import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/backoffice/login',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice/home',
    name: 'Backoffice',
    component: () => import('../views/Backoffice/BackofficeHome.vue')
  },
  {
    path: '/backoffice/logout',
    name: 'Logout',
    component: () => import('../views/Backoffice/BackofficeLogout.vue')
  },
  {
    path: '/backoffice/backoffice_admin_users',
    name: 'BackofficeAdminUsers',
    component: () => import('../views/Backoffice/BackofficeAdminUsers.vue')
  },
  {
    path: '/backoffice/backoffice_statistics',
    name: 'BackofficeStatistics',
    component: () => import('../views/Backoffice/BackofficeStatistics.vue')
  },
  {
    path: '/backoffice/backoffice_users',
    name: 'BackofficeUsers',
    component: () => import('../views/Backoffice/BackofficeUsers.vue')
  },
  {
    path: '/backoffice/user_details',
    name: 'BackofficeUserDetails',
    component: () => import('../views/Backoffice/BackofficeUserDetails.vue')
  },
  {
    path: '/backoffice/backoffice_money',
    name: 'BackofficeMoney',
    component: () => import('../views/Backoffice/BackofficeMoney.vue')
  },
  {
    path: '/backoffice/backoffice_reports',
    name: 'BackofficeReports',
    component: () => import('../views/Backoffice/BackofficeReports.vue')
  },
  {
    path: '/backoffice/backoffice_documents',
    name: 'BackofficeDocuments',
    component: () => import('../views/Backoffice/BackofficeDocuments.vue')
  },
  {
    path: '/backoffice/backoffice_reports_pending',
    name: 'BackofficeReportsPending',
    component: () => import('../views/Backoffice/BackofficeReportsPending.vue')
  },
  {
    path: '/backoffice/backoffice_documents_pending',
    name: 'BackofficeDocumentsPending',
    component: () => import('../views/Backoffice/BackofficeDocumentsPending.vue')
  },
  {
    path: '/backoffice/backoffice_set_lawyer_case',
    name: 'BackofficeSetLawyerCase',
    component: () => import('../views/Backoffice/BackofficeSetLawyerCase.vue')
  },
  {
    path: '/backoffice/backoffice_set_lawyer_document_case',
    name: 'BackofficeSetLawyerDocumentCase',
    component: () => import('../views/Backoffice/BackofficeSetLawyerDocumentCase.vue')
  },
  {
    path: '/backoffice/backoffice_reports_treatment',
    name: 'BackofficeReportsTreatment',
    component: () => import('../views/Backoffice/BackofficeReportsTreatment.vue')
  },
  {
    path: '/backoffice/backoffice_documents_treatment',
    name: 'BackofficeDocumentsTreatment',
    component: () => import('../views/Backoffice/BackofficeDocumentsTreatment.vue')
  },
  {
    path: '/backoffice/backoffice_reports_treatment_ended',
    name: 'BackofficeReportsTreatmentEnded',
    component: () => import('../views/Backoffice/BackofficeReportsTreatmentEnded.vue')
  },
  {
    path: '/backoffice/backoffice_documents_treatment_ended',
    name: 'BackofficeDocumentsTreatmentEnded',
    component: () => import('../views/Backoffice/BackofficeDocumentsTreatmentEnded.vue')
  },
  {
    path: '/backoffice/backoffice_reports_links',
    name: 'BackofficeReportsLinks',
    component: () => import('../views/Backoffice/BackofficeReportsLinks.vue')
  },
  {
    path: '/backoffice/backoffice_lawyers',
    name: 'BackofficeLawyers',
    component: () => import('../views/Backoffice/BackofficeLawyers.vue')
  },
  {
    path: '/backoffice/wysiwyg',
    name: 'Wysiwyg',
    component: () => import('../views/Backoffice/BackofficeWysiwig.vue')
  },
  {
    path: '/backoffice/forgot_password',
    name: 'BackofficeForgotPassword',
    component: () => import('../views/Backoffice/BackofficeForgotPassword.vue')
  },
  {
    path: '/backoffice/backoffice_admin_user_delete',
    name: 'BackofficeAdminUserDelete',
    component: () => import('../views/Backoffice/BackofficeAdminUserDelete.vue')
  },
  {
    path: '/backoffice/backoffice_admin_user_edit',
    name: 'BackofficeAdminUserEdit',
    component: () => import('../views/Backoffice/BackofficeAdminUserEdit.vue')
  },
  {
    path: '/backoffice/backoffice_lawyer_change_password',
    name: 'BackofficeLawyerChangePassword',
    component: () => import('../views/Backoffice/BackofficeLawyerChangePassword.vue')
  },
  {
    path: '/backoffice/backoffice_lawyers',
    name: 'BackofficeLawyers',
    component: () => import('../views/Backoffice/BackofficeLawyers.vue')
  },
  {
    path: '/backoffice/backoffice_lawyer_edit',
    name: 'BackofficeLawyerEdit',
    component: () => import('../views/Backoffice/BackofficeLawyerEdit.vue')
  },
  {
    path: '/backoffice/backoffice_lawyer_block',
    name: 'BackofficeLawyarBlock',
    component: () => import('../views/Backoffice/BackofficeLawyarBlock.vue')
  },
  {
    path: '/backoffice/block_user',
    name: 'BackofficeBlockUser',
    component: () => import('../views/Backoffice/BackofficeBlockUser.vue')
  },
  {
    path: '/backoffice/file_uploader',
    name: 'BackofficeFileUploader',
    component: () => import('../views/Backoffice/BackofficeFileUploader.vue')
  },
  {
    path: '/backoffice/cloudinary_file_uploader',
    name: 'BackofficeCloudinaryFileUploader',
    component: () => import('../views/Backoffice/BackofficeCloudinaryFileUploader.vue')
  },
  {
    path: '/backoffice/s3_file_uploader',
    name: 'BackofficeS3FileUploader',
    component: () => import('../views/Backoffice/BackofficeS3FileUploader.vue')
  },
  {
    path: '/backoffice/payme',
    name: 'BackofficePayme',
    component: () => import('../views/Backoffice/BackofficePayme.vue')
  },
  {
    path: '/backoffice/payme_ty',
    name: 'BackofficePaymeTy',
    component: () => import('../views/Backoffice/BackofficePaymeTy.vue')
  },
  
  ///////////////Lawyers///////////////
  {
    path: '/lawyers/login',
    name: 'LawyersLogin',
    component: () => import('../views/Lawyers/LawyersLogin.vue')
  },
  {
    path: '/lawyers',
    name: 'LawyersLogin',
    component: () => import('../views/Lawyers/LawyersLogin.vue')
  },
  {
    path: '/lawyers/home',
    name: 'Home',
    component: () => import('../views/Lawyers/LawyersHome.vue')
  },
  {
    path: '/lawyers/lawyers_statistics',
    name: 'LawyersStatistics',
    component: () => import('../views/Lawyers/LawyersStatistics.vue')
  },
  {
    path: '/lawyers/lawyers_reports',
    name: 'LawyersRepots',
    component: () => import('../views/Lawyers/LawyersRepots.vue')
  },
  {
    path: '/lawyers/lawyers_documents',
    name: 'LawyersDocuments',
    component: () => import('../views/Lawyers/LawyersDocuments.vue')
  },
  {
    path: '/lawyers/report_details',
    name: 'LawyersRepotDetails',
    component: () => import('../views/Lawyers/LawyersRepotDetails.vue')
  },
  // {
  //   path: '/lawyers/document_details',
  //   name: 'LawyersDocumentDetails',
  //   component: () => import('../views/Lawyers/LawyersDocumentDetails.vue')
  // },
  {
    path: '/lawyers/update_report_details',
    name: 'LawyersUpdateRepotDetails',
    component: () => import('../views/Lawyers/LawyersUpdateRepotDetails.vue')
  },
  {
    path: '/lawyers/update_document_details',
    name: 'LawyersUpdateDocumentDetails',
    component: () => import('../views/Lawyers/LawyersUpdateDocumentDetails.vue')
  },
  {
    path: '/lawyers/lawyers_settings',
    name: 'LawyersSettings',
    component: () => import('../views/Lawyers/LawyersSettings.vue')
  },
  {
    path: '/lawyers/lawyers_edit_profile',
    name: 'LawyersEditProfile',
    component: () => import('../views/Lawyers/LawyersEditProfile.vue')
  },
  {
    path: '/lawyers/lawyers_logout',
    name: 'LawyersLogout',
    component: () => import('../views/Lawyers/LawyersLogout.vue')
  },
  ///////////////ENDUSER///////////////////

  {
    path: '/enduser/register',
    name: 'EnduserRegister',
    component: () => import('../views/Enduser/EnduserRegister.vue')
  },
  {
    path: '/enduser/verify_sms',
    name: 'EnduserVerifySms',
    component: () => import('../views/Enduser/EnduserVerifySms.vue')
  },
  {
    path: '/enduser/register_step2',
    name: 'EnduserRegisterStep2',
    component: () => import('../views/Enduser/EnduserRegisterStep2.vue')
  },
  {
    path: '/enduser/login',
    name: 'EnduserLogin',
    component: () => import('../views/Enduser/EnduserLogin.vue')
  },
  {
    path: '/enduser/logout',
    name: 'EnduserLogout',
    component: () => import('../views/Enduser/EnduserLogout.vue')
  },
  {
    path: '/enduser/home',
    name: 'EnduserHome',
    component: () => import('../views/Enduser/EnduserHome.vue')
  },
  {
    path: '/enduser/profile',
    name: 'EnduserProfile',
    component: () => import('../views/Enduser/EnduserProfile.vue')
  },
  {
    path: '/enduser/edit_profile',
    name: 'EnduserEditProfile',
    component: () => import('../views/Enduser/EnduserEditProfile.vue')
  },
  {
    path: '/enduser/feasibility_test',
    name: 'EnduserFeasibilityTest',
    component: () => import('../views/Enduser/EnduserFeasibilityTest.vue')
  },
  {
    path: '/enduser/preinspection_report',
    name: 'EnduserPreInspectionReports',
    component: () => import('../views/Enduser/EnduserPreInspectionReports.vue')
  },
  {
    path: '/enduser/my_reports',
    name: 'EnduserMyReprts',
    component: () => import('../views/Enduser/EnduserMyReprts.vue')
  },
  {
    path: '/enduser/report_details',
    name: 'EnduserReportDetails',
    component: () => import('../views/Enduser/EnduserReportDetails.vue')
  },
  {
    path: '/enduser/document_details',
    name: 'EnduserDocumentDetails',
    component: () => import('../views/Enduser/EnduserDocumentDetails.vue')
  },
  {
    path: '/enduser/sending_details',
    name: 'EnduserSendingDetails',
    component: () => import('../views/Enduser/EnduserSendingDetails.vue')
  },
  {
    path: '/enduser/sending_details_document',
    name: 'EnduserSendingDetailsDocuments',
    component: () => import('../views/Enduser/EnduserSendingDetailsDocuments.vue')
  },
  {
    path: '/enduser/reports_by_type',
    name: 'EnduserReportsByType',
    component: () => import('../views/Enduser/EnduserReportsByType.vue')
  },
  {
    path: '/enduser/report_paid',
    name: 'EnduserReportPaid',
    component: () => import('../views/Enduser/EnduserReportPaid.vue')
  },
  {
    path: '/enduser/sending_done',
    name: 'EnduserReportDone',
    component: () => import('../views/Enduser/EnduserReportDone.vue')
  },
  {
    path: '/enduser/legal_form',
    name: 'EnduserLegalForm',
    component: () => import('../views/Enduser/EnduserLegalForm.vue')
  },
  {
    path: '/enduser/report_form',
    name: 'EnduserReportForm',
    component: () => import('../views/Enduser/EnduserReportForm.vue')
  },
  {
    path: '/enduser/payment_node',
    name: 'EnduserPaymentNode',
    component: () => import('../views/Enduser/EnduserPaymentNode.vue')
  },
  {
    path: '/enduser/payment_node_document',
    name: 'EnduserPaymentNodeDocument',
    component: () => import('../views/Enduser/EnduserPaymentNodeDocument.vue')
  },
  {
    path: '/enduser/feedbacke',
    name: 'EnduserFeedback',
    component: () => import('../views/Enduser/EnduserFeedback.vue')
  },
  {
    path: '/enduser/payment_transition',
    name: 'EnduserPaymentTransition',
    component: () => import('../views/Enduser/EnduserPaymentTransition.vue')
  },
  {
    path: '/enduser/payment_transition_document',
    name: 'EnduserPaymentTransitionDocument',
    component: () => import('../views/Enduser/EnduserPaymentTransitionDocument.vue')
  },
  {
    path: '/enduser/payme',
    name: 'EnduserPayme',
    component: () => import('../views/Enduser/EnduserPayme.vue')
  },
  {
    path: '/enduser/payme_document',
    name: 'EnduserPaymeDocument',
    component: () => import('../views/Enduser/EnduserPaymeDocument.vue')
  },
  {
    path: '/enduser/payme_ty',
    name: 'EnduserPaymeTy',
    component: () => import('../views/Enduser/EnduserPaymeTy.vue')
  },
  {
    path: '/enduser/payme_ty_document',
    name: 'EnduserPaymeTyDocument',
    component: () => import('../views/Enduser/EnduserPaymeTyDocument.vue')
  },
  {
    path: '/enduser/contact_us',
    name: 'EnduserContactUs',
    component: () => import('../views/Enduser/EnduserContactUs.vue')
  },
  {
    path: '/enduser/beforeuploading',
    name: 'EnduserSplittingBeforeUploading',
    component: () => import('../views/Enduser/EnduserSplittingBeforeUploading.vue')
  },
  {
    path: '/hpdev',
    component: () => import('../views/HpDev.vue')
  },
  {
    path: '/',
    component: () => import('../views/Hp.vue')
  },
  // {
  //   path: '/register',
  //   component: () => import('../views/Register.vue')
  // },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
